//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-172:_2500,_6180,_1384,_3764,_3228,_3832,_1264,_9384;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-172')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-172', "_2500,_6180,_1384,_3764,_3228,_3832,_1264,_9384");
        }
      }catch (ex) {
        console.error(ex);
      }